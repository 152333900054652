/*
Reference:
http://www.bootstrapzen.com/item/135/simple-login-form-logo/
*/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

.form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  @include box-sizing(border-box);

  &:focus {
    z-index: 2;
  }
}

.wrapper {
  background: url(https://i.imgur.com/GHr12sH.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 120vh;
}

.login-form {
  margin: 130px auto;
}

form[role='login'] {
  color: #5d5d5d;
  background: #f2f2f2;
  padding: 26px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
form[role='login'] img {
  display: block;
  margin: 0 auto;
  margin-bottom: 35px;
}
form[role='login'] input,
form[role='login'] button {
  font-size: 18px;
  margin: 16px 0;
}
form[role='login'] > div {
  text-align: center;
}

.form-links {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 50px;
}
.form-links a {
  color: #fff;
}

button {
  cursor: pointer;
}
