@media (max-width: 767px) {
  .pull-right {
    text-align: left;
  }
}

@media (min-width: 500px) {
  .button-lineup {
    padding-right: 78px;
  }
}

.booking-main-header {
  margin-bottom: 50px;
}

/* @media (max-width: 766px) {
  .button-lineup {
    padding-right: 0;
  }
} */

fieldset.for-panel {
  background-color: #fcfcfc;
  border: 1px solid #999;
  border-radius: 4px;
  border-color: #bce8f1;
  padding: 20px 10px;
  background-color: #d9edf7;
  background-color: #f9fdfd;
  margin-bottom: 12px;
}
fieldset.for-panel legend {
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #4381ba;
  font-size: 14px;
  font-weight: bold;
  line-height: 10px;
  margin: inherit;
  padding: 7px;
  width: auto;
  background-color: #d9edf7;
  margin-bottom: 0;
}

.thumbnail {
  padding: 0px;
}
.panel {
  position: relative;
}
.panel > .panel-heading:after,
.panel > .panel-heading:before {
  position: absolute;
  top: 11px;
  left: -16px;
  right: 100%;
  width: 0;
  height: 0;
  display: block;
  content: ' ';
  border-color: transparent;
  border-style: solid solid outset;
  pointer-events: none;
}
.panel > .panel-heading:after {
  border-width: 7px;
  border-right-color: #f7f7f7;
  margin-top: 1px;
  margin-left: 2px;
}
.panel > .panel-heading:before {
  border-right-color: #ddd;
  border-width: 8px;
}

a:link,
a:visited {
  text-decoration: none;
  display: inline-block;
}

a:hover {
  cursor: pointer;
}

.btn-booking {
  margin-right: 3px;
}

.container-bottom {
  margin-bottom: 500px;
  margin-top: 100px;
}

hr {
  margin: 10px 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

.add-detail {
  margin-top: 20px;
  margin-left: 15px;
}

.add-detail-margin {
  margin-bottom: 20px;
}

table input.form-control {
  text-align: right;
}

.detail-submenu {
  margin-bottom: 20px;
  margin-top: -30px;
}

i.anticon.anticon-right.arrow {
  margin-left: 0;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
  background-color: #f9fdfd;
}
