#myFooter {
  color: #777;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
}

#myFooter .footer-copyright {
  background-color: #333333;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}

#myFooter .row {
  margin-bottom: 10px;
}

#myFooter .navbar-brand {
  margin-top: 20px;
  height: 30px;
}

#myFooter .footer-copyright p {
  margin: 10px;
  color: #ccc;
}

#myFooter ul {
  list-style-type: none;
  padding-left: 0;
  line-height: 1.7;
}

#myFooter h5 {
  font-size: 18px;
  color: #777;
  font-weight: bold;
  margin-top: 30px;
}

#myFooter h2 a {
  font-size: 50px;
  text-align: center;
  color: #fff;
}

#myFooter a {
  color: #777;
  text-decoration: none;
}

#myFooter a:hover,
#myFooter a:focus {
  text-decoration: none;
  color: white;
}

#myFooter .social-networks {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 16px;
}

#myFooter .social-networks a {
  font-size: 32px;
  color: #777;
  padding: 10px;
  transition: 0.2s;
}

#myFooter .social-networks a:hover {
  text-decoration: none;
}

#myFooter .facebook:hover {
  color: #0077e2;
}

#myFooter .google:hover {
  color: #ef1a1a;
}

#myFooter .twitter:hover {
  color: #00aced;
}

#myFooter .btn {
  color: white;
  background-color: #d84b6b;
  border-radius: 20px;
  border: none;
  width: 150px;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  line-height: 25px;
}

@media screen and (max-width: 767px) {
  #myFooter {
    text-align: center;
  }
}
