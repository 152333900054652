@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons');
/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600'); */

#landing-header {
  z-index: 1;
  position: relative;
  text-align: center;
  padding-top: 35vh;
}

#landing-header h1 {
  color: #fff;
  font-family: 'Roboto', 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 54px;
  margin-bottom: 20px;
}

.slideshow {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

.slideshow li {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  animation: imageAnimation 50s linear infinite;
}

.slideshow li:nth-child(1) {
  background-image: url(../img/01.jpg);
}
.slideshow li:nth-child(2) {
  background-image: url(../img/02.jpg);
  animation-delay: 10s;
}
.slideshow li:nth-child(3) {
  background-image: url(../img/03.jpg);
  animation-delay: 20s;
}
.slideshow li:nth-child(4) {
  background-image: url(../img/06.jpg);
  animation-delay: 30s;
}
.slideshow li:nth-child(5) {
  background-image: url(../img/05.jpg);
  animation-delay: 40s;
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  10% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
}
