.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.containerItem {
  display: flex;
  /* margin-right: 16px; */
  margin-bottom: 16px;
}

.placeholder {
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  display: flex;
  width: 100%;
  font-size: 14px;
}
