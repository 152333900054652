@media (max-width: 767px) {
  .pie-chart {
    margin-left: -40px;
    padding: 0;
  }
}

/* .pie-chart {
  margin-left: -100px;
} */
