.col-sm-10 .col-sm-offset-1 > .container-margin-sendmail {
  margin-top: 300px;
}

textarea {
  overflow: auto;
}

i {
  cursor: pointer;
  margin-left: 15px;
}

@media (min-width: 768px) {
  .col-sm-4 {
    float: left;
  }
  .col-sm-4 {
    width: 25.16666667%;
  }
}

label .col-sm-2 {
  padding: 0;
}
