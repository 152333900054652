.container {
  margin: 5px auto;
  margin-bottom: 76px;
  padding-bottom: 32px;
}

/* .container-margin {
  margin-top: 100px;
  margin-bottom: 510px;
} */

h2 {
  margin: 50px auto;
}

.submenu {
  width: 100%;
  margin: 20px auto;
  padding-left: 10px;
}
